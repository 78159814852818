<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl w-full">

      <h2 class="text-2xl font-medium p-4 border-b-2 border-red-600">
        Talent Management
      </h2>

      <img
        src="../../assets/img/talent_management_banner.jpg"
        alt="Talent Management Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >

      <div class="p-4">
        <p class="py-4">
          People are core to the organisation success. Leaders must shape
          their workforce with the skills required to fit into their organisation
          strategy in this every-changing environment. What are the right talent
          strategies for your organisation in order to attract, retain and
          develop your skilled talents?
        </p>
        <p class="py-2">
          Powered by MRC A.I. Tools monitoring the talent trends across the globe,
          MRC works together with multinational companies to develop:
        </p>
        <ul class="px-4 pb-4 list-disc text-red-700">
          <li>Right people strategy to attract, retain and develop talents</li>
          <li>Workforce planning / transition strategy</li>
          <li>Competency framework/roadmap</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
